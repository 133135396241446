import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "cta", // cta, account, null
          buttonText: "Get started"
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Pear Commerce Privacy Policy for Pixel &amp; Website 
                  </h2>

                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                      Welcome to the Privacy Policy for the Pear Commerce Pixel Installer
                       (“Installer”) and our related website with a home page at www.pearcommerce.com 
                       (“Website”). The Installer and Website are operated by Pear Commerce, Inc., d/b/a 
                       “Pear Commerce,” and we know that privacy and data security are important to you.
                       <br></br>
                       <br></br>
                       This Privacy Policy (the “Policy”) describes the types of information Pear Commerce
                       (also referred to as “we,” “us,” or similar terms) may collect from you or that you
                       may provide when you visit our Installer or Website, and how we collect, use, disclose,
                       and share that information.
                       <br></br>
                       <br></br>
                       This Policy applies to information collected on or through the use of the Installer or
                       Website. It does not apply to information collected by us offline or through any other 
                       means. It also does not apply to information collected by any third party, such as a 
                       Google Tag Manger: You should carefully read the privacy policy of any such third party.
                      <br></br>
                      <br></br>
                      Please read this Policy carefully before sharing information with us so you understand our
                      practices regarding your information. By using our Installer or Website, you agree to the
                      terms of this Policy and our use, sharing, and disclosure of information as described below
                      or in any revisions to this Policy. If you do not agree with the practices described in this
                      Policy, you may chose not to use the Website or Installer.             
                      <br></br>
                      <br></br>
                      <h5>Information We May Collect About You and How We Collect It</h5>
                      The types of information we receive and/or collect about you through our Installer
                      or Website may include:
                      <ul>
                        <li>
                        Your activity on the Installer or Website,
                        </li>
                        <li>
                          Information about your smart phone connectivity provider, internet connection, the equipment
                          you use to access our Installer or Website, and details regarding your usage of our Installer or Website;
                        </li>
                        <li>
                          Location information;
                        </li>
                        <li>
                        Google Tag Manager Configuration (outlined later in this document);                          
                        </li>
                        <li>
                        And Records and copies of correspondence, if you contact us.
                        </li>
                      </ul>
                      <br></br>
                      <br></br>
                      We collect this information directly from you when you provide it to us by, for example, filling
                      out forms on the Installer or Website, as well as from the Google Tag Manager API. Pear
                      Commerce collects certain information about visitors to our Installer and Website
                      automatically and through automated means, including information about your data plan and
                      internet connection, your IP address, the equipment you use to access the Installer/Website,
                      and usage details.
                      <br></br>
                      <br></br>
                      We may also collect non-personal information automatically as you navigate through our
                      Installer and Website, using technologies such as cookies and analytics software. We use
                      the information gathered through these technologies and methods to improve your user
                      experience, analyze trends, track visitor movements, diagnose problems, gather information
                      on visitor preferences, and tailor our service offering to better meet your individual tastes.
                      Cookies are small text files that websites can send to your browser, which may be stored on
                      your computer, device, or system. In general, cookies cannot be used by themselves to
                      identify you. Nevertheless, you may change your website browser settings to reject cookies,
                      although please note that if you do this it may impair the functionality of our website. For
                      further information about cookies, including how to refuse cookies, please visit
                      www.allaboutcookies.org.          
                      <br></br>
                      <br></br>
                      We do not respond to “do-not-track” signals or other mechanisms that allow users to signal
                      their preferences on the collection of their personal information over time and across
                      third-party websites or online services. Our Installer and Website do not monitor for or adjust
                      any use as the result of “do-not-track” signals.   
                      <br></br>
                      <br></br>                                                     
                      <h5>How We Use the Information We Collect</h5>
                      We may use the information we collect about you, including your personal information, to: *
                      Present the Installer and Website and its content to you and optimize your experience as a
                      user,                      
                      <ul>
                        <li>
                        Provide customer support for our services, permit service and customer satisfaction
                        evaluations, or otherwise manage our relationship with you;                        
                        </li>
                        <li>
                        Notify you about changes to our Installer or Website or regarding the terms of any Offer or
                        any other item provided by us;                          
                        </li>
                        <li>
                        Communicate with you by text, e-mail, postal mail, telephone, or mobile device, or social
                        media or messaging account, about our services and/or any related item;
                        </li>
                        <li>
                        Execute updates to your Google Tag Manger Configuration (elaborated later in this
                        document);
                        </li>
                        <li>
                        Perform research and analysis about your use of, or interest in, our services, Offers, the
                        Installer or Website; and * Perform other functions as otherwise described to you at the time 
                        of collection.                       
                        </li>
                      </ul>
                      <br></br>
                      <br></br>  
                      <h5>Disclosure of Your Information</h5>
                        We may share or sell aggregate and de-identified information that does not identify you
                        personally without restriction and for any business purpose. We may share or transfer your
                        personal information if all or part of our business is sold, merged, dissolved, acquired,
                        reorganized, or subject to a similar transaction. We also reserve the right to disclose your
                        information as necessary to comply with a subpoena or similar investigative demand, court
                        order, request for cooperation from law enforcement or other governmental agency, to
                        exercise our legal rights, to defend against legal claims, or as otherwise required by law,
                        and/or to protect the rights or interests of third parties.      
                        <br></br>                
                        <br></br>
                        <h5>How our Installer/Website Uses Google Tag Manager for the Pixel Install</h5>
                        Installer’s use and transfer to any other app of information received from Google APIs will
                        adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use
                        requirements.
                        <br></br>
                        <br></br>
                        You may choose to install Pear’s pixel using Google Tag Manager and may choose to use Pear’s
                        one-click install of that pixel. In the one-click pixel installation process, Pear Commerce requests
                        several Google Tag scopes to correctly identify the Tag Container running on your website, update
                        that container with our tag/Javascript installed, and publish that new version to production.                        
                        <br></br>
                        <br></br>
                        We use the following OAuth Scopes:                        
                      <ul>
                        <li>
                        https://www.googleapis.com/auth/tagmanager.edit.containers: We use this scope to query
                        all containers, prompt the user to select the correct container, and then create a new
                        Tag and Trigger in the Workspace (or create a new empty Workspace if the existing
                        workspaces all have changes).                        
                        </li>
                        <li>
                        https://www.googleapis.com/auth/tagmanager.edit.containerversions: We take our
                        modifications and publish them into a Version                         
                        </li>
                        <li>
                        https://www.googleapis.com/auth/tagmanager.publish: We take this version and publish it
                        to live. This only occurs if the only changes to live will be the addition of the Pear
                        Commerce pixel. We check to ensure no other pending changes are pushed live, and if
                        it's unavoidable, then we don't publish.
                        </li>
                      </ul>
                        Pear Commerce doesn't save any data (including but not limited to Auth tokens), never requests
                        any personally identifiable information (including but not limited to email address or user IDs),
                        and prompts for re-authentication every time someone uses the Installer.
                        <br></br>
                        <br></br>
                        <h5>Your Choices About Collection and Use of Your Information</h5>
                        You can choose not to provide us with certain information, but that may result in you being
                        unable to use certain features of our Installer or Website, or ask questions, or engage with
                        certain functionality on our Installer or Website. You also can set your browser to notify you
                        when you receive a cookie, giving you the chance to accept or reject it. And you can opt-out
                        of receiving promotional emails from us by clicking the “unsubscribe” link in any such email
                        you receive from us. We may, however, send you non-promotional emails that do not contain
                        an unsubscribe link. We may text you to verify your mobile telephone number.                        
                      <br></br>
                      <br></br>
                      <h5>Deletion of Your Information</h5>
                        You may send us an email at customer.service@pearcommerce.com to request that we
                        delete your email and/or mobile telephone number and any related personal information
                        from our system, except that we may be required to retain certain information for
                        recordkeeping purposes.          
                      <br></br> 
                      <br></br>             
                      <h5>How We Protect Your Personal Information</h5>
                      We take appropriate security measures (including physical, technical, and administrative
                      measures) to help safeguard your personal information from unauthorized access and
                      disclosure. No system, however, can be completely secure. While we take steps to secure
                      your personal information, we do not promise that your personal information or activity or
                      Offer history will always remain secure. We are not responsible or liable for the illegal acts
                      (such as hacking) of third parties. If you have concerns about the security of your personal
                      information, please contact us at customer.service@pearcommerce.com.                      
                      <br></br>
                      <br></br>
                      <h5>Children’s Privacy</h5>
                      Our Installer and Website are aimed at a general, adult audience, and are not directed at, or
                      intended for use by, children under 13. Accordingly, we do not knowingly collect personal
                      information from children under the age of 13.                      
                      <br></br>
                      <br></br>
                      <h5>Links</h5>
                      Our Installer and Website contain links to the websites of third parties, including Google Tag
                      Manger. We are not responsible for the privacy practices, website content, safety, or security
                      of other entities or their websites, loyalty programs, apps, Installers or tools. Pear Commerce
                      shall not be liable for any damages or injury arising out of, or relating to, the content of any
                      third-party website, loyalty program, app, Installer or tool, or your use of any of
                      the foregoing. Each third-party should have a separate privacy policy and we encourage you
                      to review the privacy policies of any third parties before sharing information.
                      <br></br>
                      <br></br>
                      <h5>Changes to this Privacy Policy</h5>
                      Please note that we may change this policy from time to time. If there are changes to our
                      Privacy Policy, we will post them here and update the “Last Updated” date at the top of this
                      document. Continued use of the Installer or Website after any changes constitutes
                      acceptance of those changes. Accordingly, we encourage you to check the Privacy Policy
                      periodically for updates.                      
                      <br></br>
                      <br></br>
                      California Privacy Rights California Civil Code Section 1798.83, also known as the “Shine
                      The Light” law, permits California residents to request and obtain once a year, free of charge,
                      information about the personal information (if any) that Pear Commerce disclosed to third
                      parties for direct marketing purposes in the preceding calendar year. If applicable, this
                      information would include a list of the categories of personal information that was shared and
                      the names and addresses of all third parties with which we shared information in the
                      immediately preceding calendar year. If the nature of the third party’s business cannot be
                      determined from its name, examples of the products or services marketed may be provided.
                      If you are a California resident and would like to make such a request, please email your
                      request to: customer.service@pearcommerce.com. For Offers selected by you, this is your
                      notice that your information has been shared with: (1) The CPG entity that provides the
                      offered item, and (2) the specific Retailer that is available to fulfill / redeem the Offer selected
                      by you. Please note, third parties are not allowed to use this site to track individual California
                      residents over time and across websites.  
                      <br></br>                    
                      <br></br>
                      Visitors from Outside the United States Pear Commerce is located in the United States and
                      our Installer and Website are intended for use by individuals in the United States.
                      Accordingly, Pear Commerce’s data collection is subject only to the laws of the United
                      States, as applicable.   
                      <br></br>                   
                      <br></br>
                      <h5>How To Contact Us</h5>
                      If you have any questions about this Privacy Policy or our information-handling practices,
                      please contact us at customer.service@pearcommerce.com.                      
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
